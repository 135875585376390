const zhCNCommands = {
  "\\to": { descriptions: ["映射到", "箭头"] },
  "\\frac{}{}": { descriptions: ["分数"] },
  "\\sqrt{}": { descriptions: ["根号"] },
  "\\geq": { descriptions: ["大于等于"] },
  "\\leq": { descriptions: ["小于等于"] },
  "<": { descriptions: ["小于"] },
  ">": { descriptions: ["大于"] },
  "\\pm": { descriptions: ["正负"] },
  "=": { descriptions: ["等于"] },
  "\\neq": { descriptions: ["不等于"] },
  "\\approx": { descriptions: ["约等于"] },
  "\\nless": { descriptions: ["至少", "不小于"] },
  "\\ngtr": { descriptions: ["不大于", "小于等于"] },
  "\\nleq": { descriptions: [] },
  "\\ngeq": { descriptions: [] },
  "\\infty": { descriptions: ["无穷"] },
  "\\cdot": { descriptions: ["省略号"] },
  "\\sqrt[n]{}": { descriptions: ["方根"] },
  "^{}": { descriptions: ["指数"] },
  "^{\\circ}": { descriptions: ["度"] },
  "_{}": { descriptions: ["下标"] },
  "\\gg": { descriptions: ["远大于"] },
  "\\ll": { descriptions: ["远小于"] },
  "\\mathbb{R}": { descriptions: ["实数集"] },
  "\\mathbb{Q}": { descriptions: ["有理数集"] },
  "\\mathbb{Z}": { descriptions: ["整数集"] },
  "\\mathbb{N}": { descriptions: ["自然数集"] },
  "\\circ": { descriptions: ["复合函数"] },
  "\\square": { descriptions: [] },
  "\\blacksquare": { descriptions: [] },
  "\\wedge": { descriptions: ["合取", "且"] },
  "\\vee": { descriptions: ["析取", "或"] },
  "\\neg{}": { descriptions: ["非", "否定"] },
  "\\implies": { descriptions: ["严格蕴含"] },
  "\\Rightarrow\\Leftarrow": { descriptions: ["矛盾"] },
  "\\mapsto": { descriptions: ["映射"] },
  "\\therefore": { descriptions: ["所以", "因此"] },
  "\\iff": { descriptions: ["等价于"] },
  "\\sin{}": { descriptions: ["正弦"] },
  "\\cos{}": { descriptions: ["余弦"] },
  "\\tan{}": { descriptions: ["正切"] },
  "\\csc{}": { descriptions: ["余割"] },
  "\\sec{}": { descriptions: ["正割"] },
  "\\cot{}": { descriptions: ["余切"] },
  "\\in": { descriptions: ["属于"] },
  "\\ni": { descriptions: ["拥有"] },
  "\\notin": { descriptions: ["不属于"] },
  "\\subset": { descriptions: ["子集"] },
  "\\supset": { descriptions: ["包含"] },
  "\\supseteq": { descriptions: ["真包含"] },
  "\\not\\subset": { descriptions: ["不真包含"] },
  "\\subseteq": { descriptions: ["真包含"] },
  "\\nsubseteq": { descriptions: ["不真包含"] },
  "\\setminus": { descriptions: ["差集", "差"] },
  "\\int_{a}^{b}": { descriptions: ["有界积分"] },
  "\\int": { descriptions: ["积分"] },
  "\\iint": { descriptions: ["二重积分"] },
  "\\iiint": { descriptions: ["三重积分"] },
  "\\iiiint": { descriptions: ["四重积分"] },
  "\\oint": { descriptions: ["环路积分", "曲线积分"] },
  "\\overline{}": { descriptions: ["补元", "上横线"] },
  "\\underline{}": { descriptions: [] },
  "\\sum_{}^{}": { descriptions: [] },
  "\\prod_{}^{}": { descriptions: [] },
  "\\pi": { descriptions: [] },
  e: { descriptions: ["自然底数"] },
  "!": { descriptions: ["阶乘"] },
  "\\forall": { descriptions: ["任一", "任何"] },
  "\\exists": { descriptions: ["存在"] },
  "\\nexists": { descriptions: ["不存在"] },
  "\\exists!": { descriptions: ["有且仅有"] },
  "\\hat{}": { descriptions: ["拟合值", "预测值"] },
  "\\widehat{}": { descriptions: [] },
  "\\nabla": {
    descriptions: ["微分算子", "倒三角算子", "哈密顿算子", "劈形算子"],
  },
  "\\times": { descriptions: [] },
  "\\div": { descriptions: ["除以"] },
  "\\cap": { descriptions: ["交"] },
  "\\cup": { descriptions: ["并"] },
  "\\emptyset": { descriptions: [] },
  "\\triangle": { descriptions: ["三角"] },
  "\\angle": { descriptions: ["角"] },
  "\\cong": { descriptions: ["全等"] },
  "\\ncong": { descriptions: ["不全等"] },
  "\\sim": { descriptions: ["相似", "对等"] },
  "\\nsim": { descriptions: ["不相似", "不对等"] },
  "\\|": { descriptions: ["范数"] },
  "\\nparallel": { descriptions: [] },
  "\\perp": { descriptions: ["垂直于"] },
  "\\not\\perp": { descriptions: ["不垂直于"] },
  "\\overrightarrow{}": { descriptions: [] },
  "\\%": { descriptions: ["百分"] },
  "\\textit{}": { descriptions: ["斜体"] },
  "\\hspace{1in}": { descriptions: ["水平间距"] },
  "\\textrm{}": { descriptions: ["粗体"] },
  "{} \\bmod{}": { descriptions: ["取模", "模"] },
  "{} \\pmod{}": { descriptions: ["取模", "模"] },
  "\\equiv": { descriptions: ["恒等于", "恒成立"] },
  "\\partial": { descriptions: ["导数", "偏导数"] },
  "\\vec{}": { descriptions: ["向量"] },
  "\\underbrace{}_{}": { descriptions: ["和"] },
  "\\overbrace{}_{}": { descriptions: ["和"] },
  "\\ldots": { descriptions: [] },
  "\\vdots": { descriptions: [] },
  "\\begin{bmatrix}\n    a & b \\\\\n    c & d\n    \\end{bmatrix}": {
    descriptions: ["矩阵"],
  },
  "\\begin{pmatrix}\n    a & b \\\\\n    c & d\n    \\end{pmatrix}": {
    descriptions: ["矩阵"],
  },
  "\\left[\\begin{array}{cc|c}\n    1 & 0 & 12 \\\\\n    0 & 1 & 13\n    \\end{array}\\right]": {
    descriptions: [],
  },
  "\\lfloor {} \\rfloor": { descriptions: [] },
  "\\lceil {} \\rceil": { descriptions: ["向上取整"] },
  "\\alpha": { descriptions: [] },
  "\\beta": { descriptions: [] },
  "\\gamma": { descriptions: [] },
  "\\delta": { descriptions: [] },
  "\\Delta": { descriptions: [] },
  "\\epsilon": { descriptions: [] },
  "\\zeta": { descriptions: [] },
  "\\eta": { descriptions: [] },
  "\\theta": { descriptions: [] },
  "\\iota": { descriptions: [] },
  "\\kappa": { descriptions: [] },
  "\\lambda": { descriptions: [] },
  "\\mu": { descriptions: [] },
  "\\nu": { descriptions: [] },
  "\\omicron": { descriptions: [] },
  "\\rho": { descriptions: [] },
  "\\sigma": { descriptions: [] },
  "\\tau": { descriptions: [] },
  "\\upsilon": { descriptions: [] },
  "\\phi": { descriptions: [] },
  "\\chi": { descriptions: [] },
  "\\psi": { descriptions: [] },
  "\\omega": { descriptions: [] },
};

module.exports = zhCNCommands;
